import React from "react";
import { id } from "date-fns/locale"; // this is localization for Indonesian Language......
import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import "moment/locale/id";
moment.locale("id");

export default ({
  inputDate,
  minDate,
  label,
  maxSelectableDate,
  setDate,
  error,
  errorMessage,
  disablePast,
  disableFuture,
}) => {
  const classes = useStyle();
  return (
    <div
      style={{
        width: "100%",
      }}
      className="item-center"
    >
      <Typography
        className={"inputLabels"}
        children={label}
        id="StasiunAsal-label"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={id}>
        <MobileDatePicker
          disablePast={disablePast || undefined}
          disableFuture={disableFuture || undefined}
          DialogProps={{ className: classes.selectDepartureDate }}
          value={inputDate || ""}
          maxDate={maxSelectableDate}
          minDate={minDate}
          cancelText={""}
          onChange={(date) => setDate(date)}
          renderInput={(params) => {
            params.inputProps.value =
              (params.inputProps.value &&
                moment(params.inputProps.value, "DD/MM/YYYY").format(
                  "ddd, DD MMM YYYY"
                )) ||
              "";
            return (
              <div
                className="calendarField"
                onClick={
                  params.inputProps.onMouseDown || params.inputProps.onClick
                }
                style={{
                  border: error && "1px solid #c21616",
                }}
              >
                <Typography
                  children={params.inputProps.value}
                  className={"selectInputDataFont"}
                  id="StasiunAsal-name"
                />
                <KeyboardArrowDownIcon
                  onClick={
                    params.inputProps.onMouseDown || params.inputProps.onClick
                  }
                  className={"iconArrowInSelectInput"}
                />
              </div>
            );
          }}
        />
      </LocalizationProvider>

      <Typography
        className={classes.errorMessageCSS}
        children={errorMessage || ""}
      />
    </div>
  );
};

const useStyle = makeStyles({
  selectDepartureDate: {
    "& .MuiDialogActions-root>:first-of-type": {
      display: "none",
    },
    // giv a line after the date displayed at top in Date Picker
    "& .PrivatePickersToolbar-root": {
      borderBottom: "1px solid #DDD !important",
    },
    // removed the edit button which changed the view of the date picker
    "& .MuiPickersToolbar-penIconButton": {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    "& .MuiButton-root": {
      background: "#1A1A1A",
      color: "#FFF",
      "&:hover": {
        background: "#1A1A1A",
      },
    },
    // background color applied to date when selected and also when seledted and hover on it
    "& .Mui-selected": {
      backgroundColor: "#1A1A1A !important",
      "&:hover": {
        backgroundColor: "#1A1A1A !important",
      },
    },
  },
  errorMessageCSS: {
    color: "#c21616",
    fontSize: "0.75rem",
    fontWeight: 400,
    letterSpacing: "0.0333em",
  },
});
